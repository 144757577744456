import { Button } from 'antd';
import styled from 'styled-components';


export const UserCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 20px;
    width: 90%;
    background: #FFFFFF;
    border-radius: 20px;
    margin: 0 auto;
`
export const MinimalUserCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    
    padding: 24px;  /* Adds space inside */
    gap: 5px;
    
    width: 100%;
    max-width: 400px; /* Prevents it from being too wide */
    
    background: #FFFFFF;
    border-radius: 20px;

    margin: auto; /* Ensures it is centered inside modal */
    text-align: center;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background:${(props) => {
        return `linear-gradient(180deg, rgba(1, 0, 67, 0) 0%, rgba(1, 0, 67, 0.28) 44.27%, #010043 100%),url("${props?.backGroundImageUrl}")`
    }};
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 80%;
    min-height: 235px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    justify-content: flex-end;
    padding: 13px 20px;
`
export const MinimalImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    

    background: ${(props) => {
        // Ensure the URL is properly wrapped in quotes and escaped if needed
        const imageUrl = props?.backGroundImageUrl ? `url("${props.backGroundImageUrl}")` : 'none';
        return `linear-gradient(180deg, rgba(1, 0, 67, 0) 0%, rgba(1, 0, 67, 0.28) 44.27%, #010043 100%), ${imageUrl}`;
    }};

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;  /* Ensures full image fits inside */
    
    width: 100%;
    max-width: 300px; /* Prevents it from being too large */
    height: auto;
    min-height: 180px;
    max-height: 200px; /* Limits image height */

    border-radius: 10px;
    overflow: hidden;
    background-clip: border-box;

    margin: 20px auto; /* Adds spacing from top/bottom */
    padding: 10px;
`

export const CategoryButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 10px;
    height: 24px;
    background: #F6F6FF;
    border-radius: 50px;
    margin-right : 10px;
`

export const ViewMoreButton = styled(Button)`
    width: 117px;
    height: 36px;
    border: 1px solid #FFFFFF !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 32px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF !important;
    background: transparent !important;
`
export const Container = styled.div`
    text-align: center;
    padding: 120px;
`;

export const SubHeading = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #2C2B50;
    margin-top: 5px;
`;