import { Drawer } from "antd";
import React, { useState } from "react";
import Analytics from "../../../pages/Analytics";
import CreatorBio from "../../CreatorBio";

import { DrawerContainer } from "./style";

export const CustomDrawer = ({ onClose, drawerVisible, drawerData, likedUserUuid, getLikedUseIdList, getCategoryCount, hideConnectionButton = false, currentTab = 'findProfile' }) => {
    const [creatorProfileDetails, setCreatorProfileDetails] = useState(null);
    const [creatorProfileDetailsYt, setCreatorProfileDetailsYt] = useState(null);
    const [bio, setBio] = useState('');

    return (
        <DrawerContainer>
            <Drawer
                placement="bottom"
                onClose={() => {
                    setBio('');
                    onClose();
                    setCreatorProfileDetails(null);
                    setCreatorProfileDetailsYt(null);
                }}
                open={drawerVisible}
                key="bottom"
                style={{ background: '#EBF4FF', padding: "0px 0px" }}
                zIndex={1180}
                headerStyle={{ display: 'none' }}
                height={"90vh"}
            >
                <CreatorBio 
                creatorDetails = {drawerData}
                bio = {bio}
                likedUserUuid = {likedUserUuid}
                getLikedUseIdList = {getLikedUseIdList}
                getCategoryCount ={getCategoryCount}
                drawerView = {true}
                hideConnectionButton={hideConnectionButton}
                creatorProfileDetails ={creatorProfileDetails}
                creatorProfileDetailsYt ={creatorProfileDetailsYt}
                currentTab = {currentTab}
                />
                <Analytics
                    creatorDetails={drawerData}
                    userID={drawerData?.creator_uuid}
                    setBio={setBio}
                    drawerView={true}
                    setCreatorProfileDetails={setCreatorProfileDetails}
                    setCreatorProfileDetailsYt={setCreatorProfileDetailsYt}
                    currentTab={currentTab}
                />
            </Drawer>
        </DrawerContainer>
    );
};