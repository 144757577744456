import React from 'react';
import { Row, Col, Select } from 'antd';
import { Container, CustomButton } from '../FindProfile/style';
import UserDetails from '../UserDetails';
import { 
    MyCreatorsContainer, 
    SortByContainer, 
    EmptyStateContainer
} from './style';

const { Option } = Select;

const MyCreators = ({
    sortBy,
    setSortBy,
    getCategoryCount,
    likedUserUuid,
    getLikedUseIdList,
    myCreatorsList,
    loadingCreators,
    lastCreatorCardRef,
    categoryList,
    categorySelected,
    setCategorySelected
}) => {
    const removeItemAll = (arr, value) => {
        const newArr = [...arr]
        var i = 0;
        while (i < newArr.length) {
          if (newArr[i] === value) {
            newArr.splice(i, 1);
          } else {
            ++i;
          }
        }
        setCategorySelected(newArr)
    }

    return (
        <MyCreatorsContainer>
            <Row style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'flex-start'}}>
                <Col span={18} style={{display: 'flex', alignItems: 'flex-start'}}>
                    {categoryList.map(({ id, category }) => {                                     
                        return (
                            <CustomButton
                                isActive = {categorySelected.includes(id)}
                                key={id}
                                onClick={() => {
                                    categorySelected.includes(id) ? 
                                    removeItemAll(categorySelected, id) 
                                    : setCategorySelected([...categorySelected, id]) 
                                }}
                            >
                                {category}
                            </CustomButton>
                        )
                    })}
                </Col>
                <Col span={6}>
                    <SortByContainer>
                        <Select
                            showArrow = {true}
                            placeholder="Sort By"
                            onChange={(value) => setSortBy(value)}
                            value={sortBy}
                        >
                            <Option value="-total_follower_count">Follower Count (Descending)</Option>
                            <Option value="total_follower_count">Follower Count (Ascending)</Option>
                        </Select>
                    </SortByContainer>
                </Col>
            </Row>
            <Container>
                <UserDetails
                    creatorsList={myCreatorsList}
                    lastCreatorCardRef={lastCreatorCardRef}
                    loadingCreators={loadingCreators}
                    getCategoryCount={getCategoryCount}
                    likedUserUuid={likedUserUuid}
                    getLikedUseIdList={getLikedUseIdList}
                />
            </Container>
        </MyCreatorsContainer>
    );
};

export default MyCreators; 