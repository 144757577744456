import { Input, notification, Row, Select, Col } from "antd";
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import React, { useEffect, useState, useRef, useCallback  } from "react";
import { parseJwtToken } from "../../../utils";
import UserDetails from "../UserDetails";
import { Container, Heading, SortByContainer } from "./style";
import { CustomButton } from "../FindProfile/style";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";

const { Option } = Select;
const LikedProfile = ({
    getCategoryCount,
    sortBy,
    setSortBy,
    likedUserUuid,
    getLikedUseIdList,
    useRef,
    categoryList,
    categorySelected,
    setCategorySelected
}) => {
    const observer = useRef()
    const [hasMore,setHasMore] = useState(false)
    const [page,setPage] = useState(1)
    const [creatorsList, setCreatorsList] = useState([])
    const [loadingCreators, setLoadingCreators] = useState(false)
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const lastCreatorCardRef = useCallback((node)=>{
        if(loadingCreators) {
            return
        }
        if(observer.current){
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore){
                setPage(prevPage => prevPage + 1)
            }
        })
        if(node){
            observer.current.observe(node)
        }
    },[loadingCreators,hasMore])

    const getLikedCreatersList = async(page) => {
        setLoadingCreators(true)
        const postData = {
            "filters": {},
            "sortby": sortBy,
            "page": page
        }
        try{
            const response = await apiClient.post(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/`,
            postData)
            
            if(!response?.data?.error){
                if(page === 1){
                    setCreatorsList((previosValue)=>[...response?.data?.creators])
                }
                else{
                    setCreatorsList((previosValue)=>[...previosValue,...response?.data?.creators])
                }
                if(response?.data?.page ===response?.data?.total_pages){
                    setHasMore(false)
                }else{
                    setHasMore(true)
                }
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            setLoadingCreators(false)
        }catch(error){
            // notification.error({message : error?.response?.data?.message})
            setLoadingCreators(false)
        }
    }

    useEffect(()=>{
        if(page !== 1){
            getLikedCreatersList(page)
        }
    },[page])

    useEffect(()=>{
        setPage(1)
        setCreatorsList(()=>[])
        setTimeout(() => {
            getLikedCreatersList(1)
        }, 100);
    },[sortBy])
    
    const removeItemAll = (arr, value) => {
        const newArr = [...arr]
        var i = 0;
        while (i < newArr.length) {
          if (newArr[i] === value) {
            newArr.splice(i, 1);
          } else {
            ++i;
          }
        }
        setCategorySelected(newArr)
    }

    return(
        <Container>
            <Row style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'flex-start'}}>
                <Col span={18} style={{display: 'flex', alignItems: 'flex-start'}}>
                    {categoryList.map(({ id, category }) => {                                     
                        return (
                            <CustomButton
                                isActive = {categorySelected.includes(id)}
                                key={id}
                                onClick={() => {
                                    categorySelected.includes(id) ? 
                                    removeItemAll(categorySelected, id) 
                                    : setCategorySelected([...categorySelected, id]) 
                                }}
                            >
                                {category}
                            </CustomButton>
                        )
                    })}
                </Col>
                <Col span={6}>
                    <SortByContainer>
                        <Select
                            showArrow = {true}
                            placeholder="Sort By"
                            onChange={(value)=>{setSortBy(value)}}
                            value = {sortBy}
                        >
                            <Option value="-total_follower_count">Follower Count (Descending)</Option>
                            <Option value="total_follower_count">Follower Count (Ascending)</Option>
                        </Select>
                    </SortByContainer>
                </Col>
            </Row>
            <UserDetails
            creatorsList = {creatorsList}
            useRef = {useRef}
            lastCreatorCardRef ={lastCreatorCardRef}
            loadingCreators = {loadingCreators}
            getCategoryCount ={getCategoryCount}
            likedUserUuid = {likedUserUuid}
            getLikedUseIdList = {getLikedUseIdList}
            />
        </Container>
    )
}

export default LikedProfile