import { Col, notification, Tabs, Layout, Menu, Tooltip } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import React, { useEffect, useState } from "react";
import CompleteYourProfile from "../../components/CompleteYourProfile";
import ConnectInstagram from "../../components/ConnectInstagram";
import CreatorAnalytics from "../../components/CreatorAnalytics";
import CreatorYoutubeAnalytics from "../../components/CreatorYoutubeAnalytics";
import { parseJwtToken } from "../../utils";
import { AnalyticsContainer, CompanyDetails, CompanyLogoContainer } from "./style";
import { MehFilled, PlusOutlined } from '@ant-design/icons';
import Item from "antd/es/list/Item";
import InstagramAnalytics from "../../components/InstagramAnalytics";
import YoutubeAnalytics from "../../components/YoutubeAnalytics";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { isMobile } from "../../utils/constants/inedx";
import { FlexEndContainer } from "../../utils/common";

const Analytics = ({ creatorDetails, setCreatorDetails, userID, setBio, drawerView, setCreatorProfileDetails, setCreatorProfileDetailsYt, currentTab }) => {

    const navigate = useNavigate()
    const [analyticsData, setAnalyticsData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isYtLoading, setIsYtLoading] = useState(false)
    const [analyticsYoutubeData, setAnalyticsYoutubeData] = useState(null)

    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))

    const getCreatorAnalytics = async () => {
        setAnalyticsData(null)
        if (setBio) {
            setBio('')
        }
        setIsLoading(true)
        try {
            let response;
            console.log('Analytics currentTab:', currentTab);
            console.log('Analytics creatorDetails:', creatorDetails);
            if (currentTab === 'findProfile' && creatorDetails?.scraped_id) {
                console.log('Using open-analytics endpoint with scraped_id:', creatorDetails?.scraped_id);
                // Use open-analytics endpoint for Find profiles with scraped_id
                response = await apiClient.get(`${BASE_URL}/ig/media/open-analytics/${creatorDetails?.scraped_id}/`)
            } else {
                console.log('Using regular analytics endpoint with userID:', userID);
                // Use regular analytics endpoint for other tabs with userID
                response = await apiClient.get(`${BASE_URL}/ig/media/analytics/${userID}/`)
            }

            if (!response?.data?.error) {
                setAnalyticsData(response?.data)
                setCreatorProfileDetails(response?.data)
                if (setBio) {
                    setBio(response?.data?.profile_details?.bio)
                }
            }
            setIsLoading(false)
        } catch (error) {
            notification.error({ message: error?.response?.data?.message || error?.message })
            setAnalyticsData(null)
            setIsLoading(false)
        }
    }

    const getYoutubeCreatorAnalytics = async () => {
        setAnalyticsYoutubeData(null)
        if (setBio) {
            setBio('')
        }
        setIsYtLoading(true)
        try {
            const response = await apiClient.get(`${BASE_URL}/yt/videos/analytics/${userID}/`)

            if (!response?.data?.error) {
                setAnalyticsYoutubeData(response?.data)
                setCreatorProfileDetailsYt(response?.data)
                if (setBio) {
                    setBio(response?.data?.profile_details?.bio)
                }
            }
            setIsYtLoading(false)
        } catch (error) {
            console.log(error)
            notification.error({ message: error?.response?.data?.message || error?.message })
            setAnalyticsYoutubeData(null)
            setIsYtLoading(false)
        }
    }

    useEffect(() => {
        let isIGAnalyticsReady = false;
        let isYTAnalyticsReady = false;
        if (refresh_token?.user_type === "Brand") {
            isIGAnalyticsReady = creatorDetails?.ig_handle?.length;
            isYTAnalyticsReady = creatorDetails?.yt_handle?.length;
        } else {
            isIGAnalyticsReady = creatorDetails?.is_instagram_connected && creatorDetails?.related_ig_data?.insights_ready;
            isYTAnalyticsReady = creatorDetails?.is_youtube_connected && creatorDetails?.related_yt_data?.insights_ready;

        }
        if (userID) {
            if (isIGAnalyticsReady && isYTAnalyticsReady && userID) {
                getCreatorAnalytics()
                getYoutubeCreatorAnalytics()
            } else if (isIGAnalyticsReady && userID) {
                getCreatorAnalytics()
                setAnalyticsYoutubeData(null)
            } else if (isYTAnalyticsReady && userID) {
                setAnalyticsData(null)
                getYoutubeCreatorAnalytics()
            }
        }


    }, [userID, creatorDetails])

    const { Sider } = Layout;

    return (
        <AnalyticsContainer>
            <Tabs
                defaultActiveKey="instagram"
                tabBarExtraContent={!drawerView &&
                    <FlexEndContainer style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => { navigate('/connect-accounts') }}>
                        <Tooltip placement="topLeft" title={"Connect Accounts"} color="#4E4BEE">
                        <PlusOutlined className="plus-outlined" />
                        {
                            !isMobile && (
                                <span style={{ color: '#0F0E1B', fontSize: '16px' }}>
                                    Connect Accounts
                                </span>
                            )
                        }
                        </Tooltip>
                    </FlexEndContainer>
                }
            >
                {analyticsData &&
                    <TabPane
                        tab={<FlexEndContainer style={{ alignItems: 'center' }}>
                            <CompanyLogoContainer>
                                <img
                                    src={!drawerView ? creatorDetails?.related_ig_data?.profile_picture_url : analyticsData?.profile_details?.profile_pic_url}
                                    alt="logo"
                                    style={{
                                        height: '28px',
                                        width: '28px',
                                        borderRadius: '50%',
                                    }}
                                />
                                <img
                                    src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg"
                                    alt="logo"
                                    style={{
                                        height: '18px',
                                        width: '18px',
                                        borderRadius: '50%',
                                        marginLeft: '-8px'
                                    }}
                                />
                            </CompanyLogoContainer>
                            <CompanyDetails>
                                {`@${analyticsData?.profile_details?.ig_handle}`}
                            </CompanyDetails>
                        </FlexEndContainer>}
                        key="instagram"
                    >
                        <InstagramAnalytics
                            analyticsData={analyticsData}
                            setAnalyticsData={setAnalyticsData}
                            isLoading={isLoading}
                        />

                    </TabPane>
                }
                {analyticsYoutubeData &&
                    <TabPane
                        tab={
                            <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                                <CompanyLogoContainer>
                                    <img
                                        src={!drawerView ? creatorDetails?.related_yt_data?.thumbnail_high : analyticsYoutubeData?.profile_details?.thumbnails?.high}
                                        alt="logo"
                                        style={{
                                            height: '28px',
                                            width: '28px',
                                            borderRadius: '50%',
                                        }}
                                    />
                                    <img
                                        src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                        alt="logo"
                                        style={{
                                            height: '18px',
                                            width: '18px',
                                            borderRadius: '50%',
                                            marginLeft: '-8px'
                                        }}
                                    />
                                </CompanyLogoContainer>
                                <CompanyDetails>
                                    {`${analyticsYoutubeData?.profile_details?.channel_handle}`}
                                </CompanyDetails>
                            </Col>
                        }
                        key="youtube"
                    >
                        <YoutubeAnalytics
                            analyticsData={analyticsYoutubeData}
                            setAnalyticsData={setAnalyticsYoutubeData}
                            isLoading={isYtLoading}
                        />
                    </TabPane>
                }
            </Tabs>

        </AnalyticsContainer>
    )

}

export default Analytics