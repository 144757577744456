import axios from "axios";
import { notification } from 'antd';
import { BASE_URL } from "../utils/HelperFunctions/constants";

// Create Axios instance
const apiClient = axios.create({
    baseURL: BASE_URL,
});

let isRefreshing = false;
let failedQueue = [];
let navigateFn = null;

export const setNavigator = (navigate) => {
    navigateFn = navigate;
};

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

// Request Interceptor to add access token
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (
            error.response?.status === 401 && 
            !originalRequest._retry &&
            !originalRequest.url.includes('/token/obtain/')
        ) {
            originalRequest._retry = true;

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        return apiClient(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            isRefreshing = true;
            const refreshToken = localStorage.getItem("refresh_token");

            const handleSessionExpired = () => {
                notification.error({
                    message: 'Session expired. Please login again.'
                });
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                if (navigateFn) {
                    navigateFn('/Sign-in');
                }
            };


            if (!refreshToken) {
                handleSessionExpired();
                return Promise.reject(error);
            }

            try {
                const response = await axios.post(`${BASE_URL}/users/token/refresh/`, {
                    refresh: refreshToken,
                });

                const newToken = response.data.access;
                localStorage.setItem("access_token", newToken);
                apiClient.defaults.headers.Authorization = `Bearer ${newToken}`;
                processQueue(null, newToken);
                return apiClient(originalRequest);
            } catch (err) {
                processQueue(err, null);
                handleSessionExpired();
                return new Promise(() => {});
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;
