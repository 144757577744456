import { Button } from 'antd';
import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction : column;
    width : 100%;
    

    .ant-input-affix-wrapper{
        align-items: center;
        padding: 12px;
        gap: 10px;

        width: 212px;
        height: 41px;

        background: #FFFFFF;
        border: 1px solid rgba(1, 0, 67, 0.2);
        border-radius: 60px;
        border-inline-start-width : 1px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        width : 0px !important;
    }
    .ant-radio-button-wrapper{
        margin: 0 10px;
        height : 45px;
        padding: 12px 24px;
        display : flex;
        align-items : center;
        margin-bottom : 10px;
        border-radius : 60px;
        border-inline-start-width : 1px;
        border: 0px;
        color : rgba(0, 0, 0, 0.88) !important;
    }
    .ant-radio-button-wrapper-checked{
        border :1px solid #F86624 !important;
        font-weight: 500;
        color: #F86624 !important;
    }
    .ant-radio-group{
        display : flex;
        width : 80%;
    }

`
export const Heading = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #010043;
`

export const SortByContainer = styled.div`
    display : flex;
    width : 100%;
    // justify-content: flex-end;
    margin-top: 10px;
    margin-left: 58px;
    .ant-select-selector{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        padding: 12px 24px;
        gap: 16px;
        width: 126.95px;
        height: 41px;
        border: 1px solid rgba(1, 0, 67, 0.2);
        border-radius: 24px;
    }
`
export const CustomButton = styled(Button)`
    display : flex;
    height : 40px;
    margin: 5px;
    border-radius : 60px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF !important;
    border: ${(props) => {
        switch (props.isActive) {
          case true:
            return '1px solid #F86624 !important'
          default:
            return '0px !important';
        }
    }};
    color: ${(props) => {
        switch (props.isActive) {
          case true:
            return '#F86624 !important'
          default:
            return '#010043 !important';
        }
    }};
    
`