import React, { useState, useEffect } from "react";
import { Container, HeaderContainer, NavLinks, MobileMenuButton, DrawerContainer, DrawerNavLinks, DrawerOverlay, CloseButton, DrawerHeader, DrawerTitle } from "./style";
import { Button, Image } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { MenuOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';

const Header = ({isMobile, headerStyle = {}}) => {
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 35) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <>
            <HeaderContainer isScrolled={isScrolled} style={headerStyle}>
                <div style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: !isMobile ? '0px 80px' : '0px 20px',
                    width: "100%"
                }}>
                    <span style={{fontWeight: 700, fontSize: '18px', lineHeight: '22px'}}>
                        <Image 
                            style={{height: !isMobile ? '42px' : '32px', cursor: 'pointer'}}
                            preview={false}
                            onClick={() => navigate('/')}
                            src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg"
                        />
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NavLinks>
                            <a href="https://blogs.admirian.com" target="_blank" rel="noopener noreferrer">
                                Blogs
                            </a>
                        </NavLinks>
                        {!isMobile && (
                            <span>
                                <Link to={'/Sign-in'}>
                                    <Button
                                        style={{
                                            background: "#010043",
                                            borderRadius: "110px",
                                            fontSize: "16px",
                                            lineHeight: "19px",
                                            color: "#FFFFFF",
                                            marginRight: "10px",
                                            border: "0px"
                                        }}>
                                        Sign in
                                    </Button>
                                </Link>
                                <Link to={'/Sign-up'}>
                                    <Button
                                        style={{
                                            background: "#F86624",
                                            borderRadius: "110px",
                                            fontSize: "16px",
                                            lineHeight: "19px",
                                            color: "#FFFFFF",
                                            border: "0px"
                                        }}>
                                        Join Now
                                    </Button>
                                </Link>
                            </span>
                        )}
                        <MobileMenuButton onClick={toggleDrawer}>
                            <MenuOutlined />
                        </MobileMenuButton>
                    </div>
                </div>
            </HeaderContainer>

            <DrawerOverlay isOpen={isDrawerOpen} onClick={toggleDrawer} />
            <DrawerContainer isOpen={isDrawerOpen}>
                <DrawerHeader>
                    <DrawerTitle>Menu</DrawerTitle>
                    <CloseButton onClick={toggleDrawer}>
                        <CloseOutlined />
                    </CloseButton>
                </DrawerHeader>
                <DrawerNavLinks>
                    <a href="https://blogs.admirian.com" target="_blank" rel="noopener noreferrer" onClick={toggleDrawer}>
                        Blogs
                        <ArrowRightOutlined />
                    </a>
                    <Link to="/Sign-in" onClick={toggleDrawer}>
                        Sign In
                        <ArrowRightOutlined />
                    </Link>
                    <Link to="/Sign-up" onClick={toggleDrawer}>
                        Join Now
                        <ArrowRightOutlined />
                    </Link>
                </DrawerNavLinks>
            </DrawerContainer>
        </>
    );
};

export default Header;